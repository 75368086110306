import { create } from "zustand";

interface ArticleModalState {
  isModalOpen: boolean;
  modalReferer?: string;
  setModalReferer: (modalReferer?: string) => void;
  setModalIsOpen: (isModalOpen: boolean) => void;
}

export const useArticleModalStore = create<ArticleModalState>()((set) => ({
  isModalOpen: false,
  setModalReferer: (modalReferer?: string) =>
    set((state) => ({ ...state, modalReferer })),
  setModalIsOpen: (isModalOpen: boolean) =>
    set((state) => ({ ...state, isModalOpen })),
}));
