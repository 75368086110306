import Image from "next/image";
import cn from "classnames";

import type { ArticleStyle } from "../../../types/content";

import styles from "./TeaserImage.module.scss";
import { sizeNameToSizeValues } from "../../../utils/image";

interface TeaserImageProps {
  imageUrl?: string;
  size: "small" | "large";
  style: ArticleStyle;
  alt?: string;
  hasPriorityImage?: boolean;
}

const TeaserImage = ({
  imageUrl,
  size,
  style,
  alt,
  hasPriorityImage,
}: TeaserImageProps) => {
  if (!imageUrl) {
    return null;
  }

  const imgProps = sizeNameToSizeValues[size];

  return (
    <div className={cn([styles.teaserImage, styles[size], styles[style]])}>
      <Image
        src={imageUrl}
        width={imgProps.width}
        height={imgProps.height}
        alt={alt || ""}
        priority={hasPriorityImage}
      />
    </div>
  );
};

export { TeaserImage };
