"use client";

import { useEffect, useRef } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import type { PageType } from "@schibsted/pulse-news-media";
import type { Article } from "../../types/content";
import type { ReadingContext } from "../../types/pulse";

import { usePulse } from "../../pulse/usePulse";
import { updateOrigin, updateObjectPage } from "../../pulse";
import {
  isArticlePage,
  isCategoryPage,
  isFrontPage,
  isNewsMixPage,
  isTopicPage,
} from "../../utils/page";
import { trackViewListPage } from "../../pulse/event/trackListingPage";
import { getTitleFromSlug } from "../../utils/getTitleFromSlug";

interface PulseViewEventsProps {
  type: PageType;
  id?: string;
  article?: Article;
  readingContext?: ReadingContext;
}

const PulseViewEvent = ({
  type,
  id,
  article,
  readingContext,
}: PulseViewEventsProps) => {
  const {
    trackViewArticle,
    trackViewFrontpage,
    trackPageLeave,
    trackViewNewsMixPage,
  } = usePulse();
  const searchParams = useSearchParams();
  const abortController = useRef<AbortController>();
  const pathname = usePathname();
  const runOnce = useRef(true);

  useEffect(() => {
    const currentUrl = window.location.href;

    const verifyReadingContext = () => {
      return readingContext === "modal" || readingContext === "page";
    };

    const onVisibilityChange = () => {
      if (document.hidden) {
        onBeforeUnload();
      }
    };

    const onBeforeUnload = () => {
      if (runOnce.current) {
        trackPageLeave();
        runOnce.current = false;
        if (abortController.current) {
          abortController.current.abort();
        }
      }
    };

    if (type === "Frontpage" && id && isFrontPage(pathname) && runOnce) {
      updateObjectPage({
        id: id,
        type,
        url: currentUrl,
        name: id,
      });

      trackViewFrontpage(id);

      updateOrigin({
        id: id,
        type,
        url: currentUrl,
        name: id,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });

      runOnce.current = false;
    }

    if (
      type === "Article" &&
      article &&
      readingContext &&
      verifyReadingContext() &&
      isArticlePage(pathname) &&
      article.article_id === pathname.split("/").at(-1) &&
      runOnce
    ) {
      updateObjectPage({
        id: article.article_id,
        type,
        url: currentUrl,
        name: article.title.value,
      });

      trackViewArticle(article, readingContext, currentUrl);

      abortController.current = new AbortController();

      document.addEventListener("visibilitychange", onVisibilityChange, {
        signal: abortController.current.signal,
      });
      window.addEventListener("beforeunload", onBeforeUnload, {
        signal: abortController.current.signal,
      });

      updateOrigin({
        id: article.article_id,
        type,
        url: currentUrl,
        name: article.title.value,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });

      runOnce.current = false;
    }

    if (type === "Page" && isNewsMixPage(pathname) && runOnce) {
      const newsMixPageId = "sdrn:omnise:page:newsMixSettingsPage";
      const newsMixPageName = "Newsmix Settings Page";

      updateObjectPage({
        id: newsMixPageId,
        type,
        url: currentUrl,
        name: newsMixPageName,
      });

      trackViewNewsMixPage(newsMixPageId, newsMixPageName);

      updateOrigin({
        type,
        url: currentUrl,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });
      runOnce.current = false;
    }

    if (type === "Listing" && isCategoryPage(pathname, id) && runOnce) {
      const categoryName = currentUrl.split("/").pop();
      updateObjectPage({
        id,
        type,
        url: currentUrl,
        name: categoryName,
      });

      trackViewListPage(id!, categoryName!);

      updateOrigin({
        id: id,
        type,
        url: currentUrl,
        name: categoryName,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });

      runOnce.current = false;
    }

    if (type === "Listing" && isTopicPage(pathname, id) && runOnce) {
      const splittedUrl = currentUrl.split("/");
      const lengthOfSplittedUrl = splittedUrl.length;
      const topicNameSlug = splittedUrl.slice(
        lengthOfSplittedUrl - 2,
        lengthOfSplittedUrl - 1,
      )[0];

      const topicName = getTitleFromSlug(topicNameSlug);

      updateObjectPage({
        id,
        type,
        url: currentUrl,
        name: topicName,
      });

      trackViewListPage(id!, topicName!);

      updateOrigin({
        id: id,
        type,
        url: currentUrl,
        name: topicName,
        source: searchParams.get("utm_source") || undefined,
        channel:
          searchParams.get("utm_medium") ||
          searchParams.get("utm_channel") ||
          undefined,
        content: searchParams.get("utm_content") || undefined,
        campaign: searchParams.get("utm_campaign") || undefined,
        terms: searchParams.get("utm_terms") || undefined,
      });

      runOnce.current = false;
    }
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [
    trackViewFrontpage,
    trackViewArticle,
    trackPageLeave,
    article,
    readingContext,
    type,
    id,
    searchParams,
    pathname,
    trackViewNewsMixPage,
  ]);

  return null;
};

export { PulseViewEvent };
