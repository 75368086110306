"use client";

import { useCallback, useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";

import type { CSSProperties } from "react";
import type { ContentDisplayResource } from "../../types/content/newsfeedAdPlaceholder";
import type { AstAdObj, AstNative } from "@omni/ads";
import type { Ad as AdType } from "../../types/ads/adResource";

import { configureAndLoadAdPlacement } from "./utils/xandr";
import {
  adPlacementMap,
  parseAdPlacement,
} from "./utils/helpers/adPlacementMap";
import { getLayout } from "./utils/helpers";
import { isConsentToAll, useTcfPermission } from "../../tcf/helpers";
import { getIdentitySession } from "../../utils/auth/getSessionFromIdentity";
import { getGeoTrackingPermission } from "./utils/helpers/getGeoTrackingPermission";
import { AdInfoWrapper } from "./Adinfo/AdInfoWrapper";
import { TeaserContentDisplay } from "../Teaser/TeaserContentDisplay/TeaserContentDisplay";
import { setCustomFullscreenStyles } from "./utils/helpers/setCustomFullscreenStyles";
import styles from "./Ad.module.scss";
import { isConfiguredAndLoaded } from "./utils/constants";

type AdResource = AdType & Partial<Omit<ContentDisplayResource, "type">>;

type AdProps = {
  resource: AdResource;
  initialSize?: {
    height: number | string;
    width: number | string;
  };
  isFullScreenScroll?: boolean;
  isContentDisplay?: boolean;
  takeoverAvailable?: () => void;
  onFailure?: () => void;
  notAvailable?: () => void;
  loadAdRightAway?: boolean;
  hasDynamicSize?: boolean;
  shouldKeepSizeOnCollapse?: boolean;
};

const Ad: React.FC<AdProps> = ({
  resource,
  initialSize,
  onFailure,
  takeoverAvailable,
  notAvailable,
  isFullScreenScroll,
  isContentDisplay,
  loadAdRightAway = false,
  hasDynamicSize = false,
}) => {
  const [isViewed, setIsViewed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldShowAd, setShouldShowAd] = useState(true);
  const [position, setPosition] = useState("");
  const [customStyles, setCustomStyles] = useState<CSSProperties>({});
  const [creativeId, setCreativeId] = useState<string>("");
  const [[width, height], setPlaceholderValues] = useState<
    (null | number | string)[]
  >([initialSize?.width || null, initialSize?.height || null]);
  const [isCollapsed, setIsCollapsed] = useState(resource.startAsCollapsed);
  const [canKeepSize, setCanKeepSize] = useState(false);

  const [contentDisplay, setContentDisplayAd] = useState<AstNative>();

  const { placementContext, placementWebName, correct } = parseAdPlacement(
    resource.placement,
  );

  const marketingConsent = useTcfPermission("CMP:marketing");
  const isInArticle =
    resource.placement === "article.afterLinks" ||
    resource.placement === "article.beforeLinks";
  const id = `${placementWebName}-${position}${resource.context?.article ? `-${resource.context?.article.article_id}` : ""}`;
  const placementClassName = `placement_${resource.placement.replace(".", "-")}`;

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const onLoad = useCallback((e: AstAdObj) => {
    if (e.adType === "native") {
      setContentDisplayAd(e.native);
      e?.creativeId && setCreativeId(e.creativeId.toString());
    }
  }, []);

  const onAvailable = useCallback(
    (response: AstAdObj) => {
      if (
        response?.banner &&
        response.banner.height === 1080 &&
        response.banner.width === 1920
      ) {
        setCustomFullscreenStyles(response.targetId);
      }

      if (resource.placement === "feed.takeover" && takeoverAvailable) {
        takeoverAvailable();
      }
    },
    [resource.placement, takeoverAvailable],
  );

  const loadAd = useCallback(async (): Promise<void> => {
    if (isConfiguredAndLoaded.has(id)) {
      return;
    }

    try {
      const consentToAll = await isConsentToAll();

      const { sig, userId } = await getIdentitySession(
        resource.user.isLoggedIn,
      );

      const geoTracking = await getGeoTrackingPermission(
        resource.user.isLoggedIn,
        userId,
        sig,
      );

      const user = {
        isPremium: resource.user.isPremium,
        isLoggedIn: resource.user.isLoggedIn,
      };
      const filteredAdRule = resource.placementRules.filter(
        (ad) => ad.rules.position === position,
      );
      const adRule = { ...filteredAdRule[0] };
      const article = resource.context?.article || {};

      const { stylesFromXandr, creativeId, isNativeBanner } =
        await configureAndLoadAdPlacement({
          id,
          adRule,
          user,
          article,
          geoTracking,
          consentToAll,
          marketingConsent,
          onFailure,
          onLoad,
          onAvailable,
          notAvailable,
        });

      if (isNativeBanner) {
        creativeId && setCreativeId(creativeId);

        const xandrAdHeight = stylesFromXandr?.height
          ? !stylesFromXandr?.height.includes("%")
            ? parseInt(stylesFromXandr?.height, 10)
            : stylesFromXandr?.height
          : 0;

        const xandrAdWidth = stylesFromXandr?.width
          ? !stylesFromXandr?.width.includes("%")
            ? parseInt(stylesFromXandr?.width, 10)
            : stylesFromXandr?.width
          : 0;

        setCustomStyles({
          ...customStyles,
          ...stylesFromXandr,
          ...(xandrAdHeight && { height: xandrAdHeight }),
          ...(xandrAdWidth && { width: xandrAdWidth }),
        });
      }

      isConfiguredAndLoaded.add(id);
      setIsLoading(false);
      if (resource.startAsCollapsed) {
        setIsCollapsed(false);
      }
    } catch (e) {
      if (resource.shouldKeepSizeOnCollapse) {
        setCanKeepSize(true);
      } else {
        setShouldShowAd(false);
      }

      console.error(e);
    }
  }, [
    resource.placementRules,
    resource.user.isLoggedIn,
    resource.user.isPremium,
    resource.context.article,
    resource.shouldKeepSizeOnCollapse,
    resource.startAsCollapsed,
    id,
    position,
    customStyles,
    marketingConsent,
    onFailure,
    notAvailable,
    onLoad,
    onAvailable,
  ]);

  useEffect(() => {
    const eventListener = (event: MessageEvent) => {
      if (typeof event.data === "string" && event.data.includes("targetId")) {
        const { name, targetId } = JSON.parse(event.data);

        if (name === "hideAd" && id === targetId) {
          setShouldShowAd(false);
        }
      }
    };

    if (!isLoading && !resource.shouldKeepSizeOnCollapse) {
      window.addEventListener("message", eventListener);
      return () => window.removeEventListener("message", eventListener);
    }
  }, [id, isLoading, resource.shouldKeepSizeOnCollapse]);

  useEffect(() => {
    if ((inView && position && !isViewed) || loadAdRightAway) {
      setIsViewed(true);
      loadAd();
    }
  }, [inView, loadAd, isViewed, position, loadAdRightAway]);

  useEffect(() => {
    if (correct && !isViewed) {
      const layout = getLayout();
      const brandName =
        process.env.NEXT_PUBLIC_UI_THEME === "ekonomi" ? "ekonomi" : "core";
      const position =
        adPlacementMap[placementContext][brandName][placementWebName]?.[layout];
      const placeholderWidth = resource.placeholderValues[layout]?.width;
      const placeholderHeight = resource.placeholderValues[layout]?.height;

      if (placeholderWidth && placeholderHeight) {
        setPlaceholderValues([placeholderWidth, placeholderHeight]);
      }

      if (position) {
        setPosition(position);
      }
    }
  }, [
    placementContext,
    placementWebName,
    resource.placeholderValues,
    correct,
    isViewed,
    resource,
  ]);

  useEffect(() => {
    if (!isLoading && hasDynamicSize) {
      setCustomStyles((currStyles) => ({
        ...currStyles,
        height: "auto",
        width: "100%",
      }));
    }
  }, [hasDynamicSize, isLoading]);

  if (typeof contentDisplay !== "undefined") {
    return (
      <div id={id}>
        <TeaserContentDisplay
          ad={contentDisplay}
          isLoggedIn={resource.user.isLoggedIn}
          creativeId={creativeId}
          layout={resource.native_ad_layout}
        />
      </div>
    );
  }

  return (
    <>
      {shouldShowAd && resource.havePlacementRule ? (
        <div
          className={cn([
            styles.placement,
            isInArticle && styles.articleResource,
            (isFullScreenScroll || isContentDisplay) && styles.wideWrapper,
            isCollapsed && styles.collapsed,
            canKeepSize && styles.keepSizeButHidden,
          ])}
        >
          <div
            className={cn([
              styles.wrapper,
              isInArticle && styles.articleWrapper,
            ])}
          >
            <AdInfoWrapper
              isLoading={isLoading}
              isLoggedIn={resource.user.isLoggedIn}
              creativeId={creativeId}
              placementClassName={placementClassName}
              isWide={isFullScreenScroll || isContentDisplay}
              isFullscreenScroll={isFullScreenScroll}
            />
            <div
              style={{
                ...customStyles,
                height:
                  initialSize?.height || customStyles?.height || height || 0,
                width: initialSize?.width || customStyles?.width || width || 0,
              }}
              id={id}
              ref={ref}
              className={cn([
                isLoading ? styles.loader : "",
                isFullScreenScroll && styles.fullscreenScroll,
                hasDynamicSize && !isLoading && styles.dynamicSizing,
              ])}
            ></div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { Ad };
export type { AdResource };
