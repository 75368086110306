"use client";

import cn from "classnames";

import type { Article } from "../../types/content";
import type { Teaser as TeaserType } from "../../types/content/teaser";

import styles from "./Teaser.module.scss";
import { TeaserImage } from "./TeaserImage/TeaserImage";
import { TeaserFooter } from "./TeaserFooter/TeaserFooter";
import { TeaserText } from "./TeaserText/TeaserText";
import { TeaserHeading } from "./TeaserHeading/TeaserHeading";
import { imageSrc } from "../../utils/image";
import { TeaserLink } from "./TeaserLink/TeaserLink";
import { TeaserLargeMedia } from "./TeaserLargeMedia/TeaserLargeMedia";
import { getStaticMapSrc } from "../Map/utils/getStaticMapSrc";
import {
  getThumbnailUrl,
  isVideo,
} from "../ArticleContent/resources/Url/utils";

interface TeaserProps {
  article: Article;
  index: number;
  articlePositionInCluster?: number;
  hasPriorityImage?: boolean;
  inCluster?: boolean;
  isBox?: boolean;
  isNative?: boolean;
  boxName?: string;
}

const getMainMediaUrl = (teaser: TeaserType) => {
  if (!teaser.image) {
    return undefined;
  }

  if (teaser.image.type === "Image") {
    return imageSrc(
      teaser.layout === "large" ? "large" : "small",
      true,
      teaser.image?.type === "Image" ? teaser.image : undefined,
    );
  }

  if (teaser.image.type === "Map") {
    return getStaticMapSrc(
      {
        width: 640,
        height: teaser.layout === "large" ? 320 : 640,
      },
      teaser.image,
    );
  }

  if (teaser.image.type === "Url" && isVideo(teaser.image)) {
    return getThumbnailUrl(teaser.image, teaser.layout);
  }

  if (teaser.image.type === "ExternalImage") {
    return teaser.layout === "small"
      ? `${teaser.image.url}&w=400&h=400`
      : teaser.image.url;
  }
};

const Teaser = ({
  article,
  index,
  articlePositionInCluster,
  hasPriorityImage,
  inCluster,
  isNative,
  isBox,
  boxName,
}: TeaserProps) => {
  const { teaser, changes, style } = article;
  const { updated, published } = changes;

  const mainMediaUrl = getMainMediaUrl(teaser);

  return (
    <article className={styles.teaserContainer}>
      <div
        className={cn([styles.teaser, styles[style], styles[teaser.layout]])}
      >
        <TeaserLink
          article={article}
          articlePositionInCluster={articlePositionInCluster}
          index={index}
          imageUrl={mainMediaUrl}
          inCluster={inCluster}
          isNative={isNative}
          isBox={isBox}
          boxName={boxName}
        >
          {teaser.layout === "large" && (
            <TeaserLargeMedia
              imageUrl={mainMediaUrl}
              image={teaser.image}
              style={style}
              hasPriorityImage={hasPriorityImage}
            />
          )}
          <div className={styles.teaserContent}>
            <div className={cn([styles.teaserTextContent, styles[style]])}>
              <TeaserHeading
                title={teaser.title}
                style={style}
                layout={teaser.layout}
              />
              <TeaserText text={teaser.text} style={style} />
              <TeaserFooter
                style={style}
                type={teaser.footer}
                timestamp={updated || published || ""}
              />
            </div>
            {teaser.layout === "small" && (
              <TeaserImage
                size="small"
                imageUrl={mainMediaUrl}
                style={style}
                alt={
                  teaser.image?.type === "Image"
                    ? teaser.image?.caption?.value
                    : ""
                }
                hasPriorityImage={hasPriorityImage}
              />
            )}
          </div>
        </TeaserLink>
      </div>
    </article>
  );
};

export { Teaser };
export type { TeaserProps };
