const isArticlePage = (pathname: string) => {
  const splitPath = pathname.split("/");
  return splitPath[2] === "a";
};

const isFrontPage = (pathname: string) => {
  return ["/", "/senaste"].includes(pathname);
};

const isNewsMixPage = (pathname: string) => {
  return pathname === "/nyhetsmix";
};

const isCategoryPage = (pathname: string, id?: string) => {
  return !!id?.includes("category") && !isArticlePage(pathname);
};

const isTopicPage = (pathname: string, id?: string) => {
  return !!id?.includes("story") && !isArticlePage(pathname);
};

export {
  isArticlePage,
  isNewsMixPage,
  isFrontPage,
  isCategoryPage,
  isTopicPage,
};
